.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gridList {
  width: 500;
  height: 450;
}

div.searchBorder {
  padding: 4px 4px;
  display: flex;
  align-items: center;
  background-color: rgb(245,245,245);

}

.searchInput {
  width: 100%;
  height: 4rem;
}

.searchInputNarrow {
  width: 100%;
}

.searchInput > .MuiInputBase-input {
  font-size: 1.5rem;
}

.title.MuiTypography-h1 {
  flex-grow: 1;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.titleBar {
  margin-bottom: 20px;
  background: linear-gradient(to right,#627D77, #9AABA7, #D0D8D6)
}

@media (min-width: 1024px) {
  .logo {
    height: 2rem;
    margin-right: 4rem;
  }
}

@media (max-width: 1024px) {
  .logo {
    height: 1.2rem;
    margin-right: 2.5rem;
  }
}

input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}

.partnerList {
  max-height: 300px;
}

.partnerDomainList {
  min-height: 285px;
}

.partnerDomainListPaper {
  /*margin-top: 5.5rem;*/
}

@media (max-width: 959px) {
  .hidden {
    display: none;
  }
}

@media (min-width: 960px) {
  .hidden {
    visibility: hidden;
  }
}

@media (min-width: 960px) {
  .MuiFormControl-root.endorsementSelect {
    min-width: 300px;
  }
}

@media (max-width: 959px) {
  .MuiFormControl-root.endorsementSelect {
    min-width: 250px;
  }
}